<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>

      <b-row class="mb-2">
        <b-col
          cols="12"
          md="10"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filter_search"
              v-model="tableRawData.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Status"
            label-for="filter_status"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-select
              id="filter_status"
              v-model="tableRawData.filter.status"
              size="sm"
              :options="options.filter_statuses"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          order="3"
          class="mb-2"
        >
          <b-form-checkbox
            id="without_mobile_number"
            v-model="tableRawData.filter.without_mobile_number"
            switch
            value="1"
            unchecked-value="0"
            name="without_mobile_number"
          >
            <strong>Show Records Without Mobile Number Only?</strong>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          order="3"
        >
          <b-button
            type="button"
            variant="success"
            class="mr-2"
            size="sm"
            @click="onShowAddForm"
          >
            Add Raw Data
          </b-button>
          <b-button
            v-if="useExcelUploader"
            type="button"
            variant="secondary"
            size="sm"
            @click="onShowImportExcelForm"
          >
            Import from Excel
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="table"
            small
            hover
            responsive
            show-empty
            class="mt-2"
            :stacked="isMobile()"
            :items="tableRawDataProvider"
            :per-page="tableRawData.perPage"
            :current-page="tableRawData.currentPage"
            :fields="tableRawData.fields"
            :sort-by.sync="tableRawData.sortBy"
            :sort-desc.sync="tableRawData.sortDesc"
            :sort-direction="tableRawData.sortDirection"
            :filter="tableRawData.filter"
            :filter-included-fields="tableRawData.filterOn"
            :busy="tableRawData.busy"
          >
            <template #cell(index)="{ index }">
              {{ Number(tableRawData.currentPage) * Number(tableRawData.perPage) - Number(tableRawData.perPage) + (Number(index) + 1) }}
            </template>

            <template #cell(transferred)="{ value }">
              {{ Number(value) == 1 ? "Yes" : "No" }}
            </template>

            <template #cell()="{ value }">
              <div class="text-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  variant="success"
                  :disabled="tableRawData.busy"
                  @click="onShowEditForm(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>

        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableRawData.perPage"
              :options="tableRawData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableRawData.currentPage"
            :total-rows="tableRawData.totalRows"
            :per-page="tableRawData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-raw-data-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
      :title="ModalTitle"
      @ok="onConfirmAction"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-file-text-line h4" />
              <b class="ml-1">Farmer</b>
            </div>
          </template>

          <ValidationObserver
            ref="form"
          >
            <form
              role="form"
              novalidate
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="mobile number"
                        vid="mobile_number"
                        :rules="vMOBILENUMBER"
                      >
                        <b-form-group
                          label="Mobile Number"
                          label-for="mobile_number"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="mobile_number"
                            v-model="rawData.mobile_number"
                            type="text"
                            maxlength="11"
                            autocomplete="off"
                            placeholder="enter mobile number here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="first name"
                        vid="first_name"
                        rules="required|max:50"
                      >
                        <b-form-group
                          label="First Name"
                          label-for="first_name"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="first_name"
                            v-model="rawData.first_name"
                            type="text"
                            autocomplete="off"
                            placeholder="enter first name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="last name"
                        vid="last_name"
                        rules="required|max:50"
                      >
                        <b-form-group
                          label="Last Name"
                          label-for="last_name"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="last_name"
                            v-model="rawData.last_name"
                            type="text"
                            autocomplete="off"
                            placeholder="enter last name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="cluster"
                    vid="cluster"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="cluster"><strong>Cluster</strong></label>
                      <v-select
                        id="cluster"
                        v-model="selected.cluster"
                        :options="list.clusters"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.clusters"
                        :loading="state.fetching.clusters"
                        label="cluster_name"
                      >
                        <template #option="{ cluster_name }">
                          <strong>{{ cluster_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="area"
                    vid="area"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="areas"><strong>Area</strong></label>
                      <v-select
                        id="areas"
                        v-model="selected.area"
                        :options="list.areas"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.areas"
                        :loading="state.fetching.areas"
                        label="area_name"
                      >
                        <template #option="{ area_name }">
                          <strong>{{ area_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Areas
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->

                <!-- <b-col cols="12">
                <ValidationProvider
                  #default="{ errors }"
                  name="home address"
                  vid="home_address"
                  rules="required|max:255"
                >
                  <b-form-group>
                    <label for="home_address"><strong>Home Address</strong></label>
                    <b-textarea
                      id="home_address"
                      v-model="rawData.home_address"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                      placeholder="enter home address"
                      autocomplete="off"
                      rows="6"
                      max-rows="6"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="source"
                        vid="source"
                        rules="required"
                      >
                        <b-form-group
                          label="Source"
                          label-for="sources"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.source"
                            input-id="sources"
                            label="source_name"
                            placeholder="search source here"
                            :options="list.sources"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || state.fetching.sources"
                            :loading="state.fetching.sources"
                          >
                            <template #option="{ source_name }">
                              <strong>{{ source_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Sources
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="8"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="street name"
                        vid="street_name"
                        rules="max:255"
                      >
                        <b-form-group
                          label="Street Name"
                          label-for="street_name"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <b-input
                            id="street_name"
                            v-model="rawData.street_name"
                            type="text"
                            autocomplete="off"
                            placeholder="enter street name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="province"
                        vid="province"
                        rules="required"
                      >
                        <b-form-group
                          label="Province"
                          label-for="province"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.province"
                            input-id="province"
                            append-to-body
                            label="province_name"
                            placeholder="search province here"
                            :options="list.provinces"
                            :calculate-position="calculateDropPosition"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || state.fetching.provinces)"
                            :loading="state.fetching.provinces"
                          >
                            <template #option="{ province_name }">
                              <strong>{{ province_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Provinces
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="municipality"
                        vid="municipality"
                        rules="required"
                      >
                        <b-form-group
                          label="Municipality"
                          label-for="municipality"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.municipality"
                            input-id="municipality"
                            append-to-body
                            label="municipality_name"
                            placeholder="search municipality here"
                            :options="list.municipalities"
                            :calculate-position="calculateDropPosition"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || state.fetching.municipalities)"
                            :loading="state.fetching.municipalities"
                          >
                            <template #option="{ municipality_name }">
                              <strong>{{ municipality_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Municipalities
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="barangay"
                        vid="barangay"
                      >
                        <b-form-group
                          label="Barangay"
                          label-for="barangay"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.barangay"
                            input-id="barangay"
                            append-to-body
                            label="barangay_name"
                            placeholder="search barangay here"
                            :options="list.barangays"
                            :calculate-position="calculateDropPosition"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || state.fetching.barangays"
                            :loading="state.fetching.barangays"
                          >
                            <template #option="{ barangay_name }">
                              <strong>{{ barangay_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Barangays
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="farm address"
                    vid="farm_address"
                    rules="max:255"
                  >
                    <b-form-group
                      label="Farm Address"
                      label-for="farm_address"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="farm_address"
                        v-model="rawData.farm_address"
                        rows="6"
                        max-rows="6"
                        autocomplete="off"
                        placeholder="enter farm address here"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:3000"
                  >
                    <b-form-group
                      label="Remarks"
                      label-for="remarks"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="remarks"
                        v-model="rawData.remarks"
                        rows="6"
                        max-rows="6"
                        autocomplete="off"
                        placeholder="enter remarks here"
                        :state="(errors.length > 0 ? false : null)"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-checkbox
                    id="is_owner"
                    v-model="rawData.is_owner"
                    name="is_owner"
                    value="1"
                    unchecked-value="0"
                    switch
                  >
                    <strong>Farm Owner?</strong>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-slack-line h4" />
              <b class="ml-1">Crops</b>
            </div>
          </template>
          <ValidationObserver
            ref="formFarmerCrops"
          >
            <form
              role="form"
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="crop"
                        vid="crop"
                        rules="required"
                      >
                        <b-form-group
                          label="Crop"
                          label-for="crop"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="tableCrop.selected.crop"
                            input-id="crop"
                            label="crop_name"
                            placeholder="search crop here"
                            :options="tableCrop.options.crops"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || tableCrop.fetching.crops)"
                            :loading="tableCrop.fetching.crops"
                          >
                            <template #option="{ crop_name }">
                              <strong>{{ crop_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Crops
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      v-if="Number(tableCrop.selected.crop?.id) === 4"
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="crop name"
                        vid="other_crop"
                        rules="required|max:100"
                      >
                        <b-form-group
                          label="Crop Name"
                          label-for="other_crop"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="other_crop"
                            v-model="tableCrop.payload.other_crop"
                            type="text"
                            autocomplete="off"
                            placeholder="enter crop name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="field size"
                        vid="crop_field_size"
                        rules="required"
                      >
                        <b-form-group
                          label="Field Size"
                          label-for="crop_field_size"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="tableCrop.selected.crop_field_size"
                            input-id="crop_field_size"
                            label="field_size_name"
                            placeholder="search field size here"
                            :options="tableCrop.options.field_sizes"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || tableCrop.fetching.field_sizes)"
                            :loading="tableCrop.fetching.field_sizes"
                          >
                            <template #option="{ field_size_name }">
                              <strong>{{ field_size_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Field Sizes
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="number of hectares"
                        vid="number_of_hectares"
                        rules="required|double|min_value:0.01"
                      >
                        <b-form-group
                          label="Number of Hectares"
                          label-for="number_of_hectares"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="number_of_hectares"
                            v-model="tableCrop.payload.number_of_hectares"
                            type="number"
                            step="0.01"
                            min="0.01"
                            autocomplete="off"
                            class="text-right"
                            placeholder="enter number of hectares here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-button
                        type="button"
                        variant="success"
                        class="mr-2"
                        size="sm"
                        @click="onSaveFarmerCrop"
                      >
                        Save Crop
                      </b-button>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >

                  <b-table
                    ref="tableCrop"
                    small
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :stacked="isMobile()"
                    :items="rawData.crops"
                    :fields="tableCrop.fields"
                    :busy="tableCrop.busy"
                  >
                    <template #cell(index)="{ index }">
                      {{ (Number(index) + 1) }}
                    </template>

                    <template #cell()="{ value }">
                      <div class="text-nowrap">
                        {{ value }}
                      </div>
                    </template>

                    <template #cell(action)="{ item }">
                      <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
                        <b-button
                          size="sm"
                          variant="danger"
                          :disabled="tableCrop.busy"
                          @click="onRemoveFarmerCrop(item.order)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>

                    <template #table-busy>
                      <div class="text-center py-5">
                        <b-icon
                          icon="stopwatch"
                          font-scale="5"
                          animation="cylon"
                        />
                        <p class="h3 py-2">
                          <strong>Loading . . .</strong>
                        </p>
                      </div>
                    </template>

                  </b-table>

                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-import-from-excel"
      title="Import from Excel"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmUploadData"
    >
      <ValidationObserver ref="form2">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="source"
                vid="source"
                rules="required"
              >
                <b-form-group>
                  <label for="sources">Source</label>
                  <v-select
                    id="sources"
                    v-model="selected2.source"
                    :options="list.sources"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.uploading"
                    :loading="state.fetching.sources"
                    label="source_name"
                  >
                    <template #option="{ source_name }">
                      <strong>{{ source_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Sources
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="cluster"
                vid="cluster"
                rules="required"
              >
                <b-form-group>
                  <label for="cluster">Cluster</label>
                  <v-select
                    id="cluster"
                    v-model="selected2.cluster"
                    :options="list.clusters"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.uploading"
                    :loading="state.fetching.clusters"
                    label="cluster_name"
                  >
                    <template #option="{ cluster_name }">
                      <strong>{{ cluster_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Clusters
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="area"
                vid="area"
                rules="required"
              >
                <b-form-group>
                  <label for="areas">Area</label>
                  <v-select
                    id="areas"
                    v-model="selected2.area"
                    :options="list.areas"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.uploading"
                    :loading="state.fetching.areas"
                    label="area_name"
                  >
                    <template #option="{ area_name }">
                      <strong>{{ area_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Areas
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="province"
                vid="province"
                rules=""
              >
                <b-form-group>
                  <label for="province">Province</label>
                  <v-select
                    id="province"
                    v-model="selected2.province"
                    :options="list.provinces"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.provinces"
                    :loading="state.fetching.provinces"
                    label="province_name"
                  >
                    <template #option="{ province_name }">
                      <strong>{{ province_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Provinces
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="municipality"
                vid="municipality"
                rules=""
              >
                <b-form-group>
                  <label for="municipality">Municipality</label>
                  <v-select
                    id="municipality"
                    v-model="selected2.municipality"
                    :options="list.municipalities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.municipalities"
                    :loading="state.fetching.municipalities"
                    label="municipality_name"
                  >
                    <template #option="{ municipality_name }">
                      <strong>{{ municipality_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Municipalities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="barangay"
                vid="barangay"
                rules=""
              >
                <b-form-group>
                  <label for="barangay">Barangay</label>
                  <v-select
                    id="barangay"
                    v-model="selected2.barangay"
                    :options="list.barangays"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.barangays"
                    :loading="state.fetching.barangays"
                    label="barangay_name"
                  >
                    <template #option="{ barangay_name }">
                      <strong>{{ barangay_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Barangays
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <div
              class="col-12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-group>
                  <label for="file">Attach File:</label>
                  <b-form-file
                    v-model="excelData.file"
                    :class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    accept=".xlsx"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || excelData.file === null || list.invalidRawData.length>0 || state.uploading)"
          @click="ok()"
        >
          Upload
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-invalid-excel-data"
      title="Invalid Data"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-table
        ref="tableInvalidRawData"
        hover
        responsive
        :per-page="tableInvalidRawData.perPage"
        :current-page="tableInvalidRawData.currentPage"
        :items="list.invalidRawData"
        :fields="tableInvalidRawData.fields"
        :sort-by.sync="tableInvalidRawData.sortBy"
        :sort-desc.sync="tableInvalidRawData.sortDesc"
        :sort-direction="tableInvalidRawData.sortDirection"
        :filter="tableInvalidRawData.filter"
        :filter-included-fields="tableInvalidRawData.filterOn"
        show-empty
      >
        <template #cell(index)="{ index }">
          {{ (Number(index) + 1) }}
        </template>

        <template #cell(line_error)="data">
          <div class="text-nowrap text-danger">
            {{ data.item.line_error }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableInvalidRawData.perPage"
              :options="tableInvalidRawData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableInvalidRawData.currentPage"
            :total-rows="tableInvalidRawData.totalRows"
            :per-page="tableInvalidRawData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { AxiosError, UserRawDataService, UserImportDataService, SharedListService } from '@/services'
import readXlsxFile from 'read-excel-file'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'UserRawDataMobileNumber',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Mobile Number'
  }),

  mixins: [formatter, misc],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          clusters: false,
          areas: false,
          provinces: false,
          municipalities: false,
          barangays: false,
          sources: false
        }
      },
      list: {
        sources: [],
        clusters: [],
        areas: [],
        provinces: [],
        municipalities: [],
        barangays: [],
        invalidRawData: []
      },
      options: {
        filter_statuses: [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Invalid', value: 'Invalid' }
        ]
      },
      selected: {
        current: null,
        source: null,
        cluster: null,
        area: null,
        province: null,
        municipality: null,
        barangay: null
      },
      selected2: {
        source: null,
        cluster: null,
        area: null,
        province: null,
        municipality: null,
        barangay: null
      },
      rawData: {
        id: 0,
        mobile_number: '',
        first_name: '',
        last_name: '',
        source: null,
        cluster: null,
        area: null,
        home_address: '',
        farm_address: '',
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        remarks: '',
        crops: [],
        is_owner: 0
      },
      excelData: {
        file: null,
        source: null,
        cluster: null,
        area: null,
        province: null,
        municipality: null,
        barangay: null,
        list: []
      },
      tableRawData: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          status: 'All',
          without_mobile_number: 0
        },
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 15, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'status' },
          { mobile: 3, key: 'first_name', sortable: true },
          { mobile: 4, key: 'last_name', sortable: true },
          { mobile: 5, key: 'mobile_number', class: 'text-center' },
          { mobile: 6, key: 'logs_count', label: 'Unsuccessful Call', class: 'text-center' },
          { mobile: 7, key: 'last_call', formatter: this.dateTimeFormatter },
          { mobile: 8, key: 'unsuccessful_reason_name', label: 'Last Unsuccessful Reason' },
          { mobile: 9, key: 'last_agent_name', label: 'Last Agent' },
          { mobile: 10, key: 'province.province_name', label: 'Province' },
          { mobile: 11, key: 'municipality.municipality_name', label: 'Municipality' },
          { mobile: 12, key: 'barangay.barangay_name', label: 'Barangay' },
          { mobile: 13, key: 'transferred', class: 'text-center' },
          { mobile: 14, key: 'updated_at', formatter: this.dateTimeFormatter }
        ].sort(this.scaleOnMobile)
      },
      tableCrop: {
        busy: false,
        fetching: {
          crops: false,
          field_sizes: false
        },
        options: {
          crops: [],
          field_sizes: []
        },
        selected: {
          crop: null,
          crop_field_size: null
        },
        payload: {
          order: 0,
          crop_id: null,
          crop_name: null,
          other_crop: null,
          crop_field_size_id: null,
          crop_field_size_name: null,
          number_of_hectares: null
        },
        fields: [
          { key: 'index', label: '#' },
          { key: 'action' },
          { key: 'crop_name' },
          { key: 'other_crop' },
          { key: 'crop_field_size_name', label: 'Field Size' },
          { key: 'number_of_hectares' }
        ]
      },
      tableInvalidRawData: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'mobile_number' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'line_number' },
          { key: 'line_error' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    useExcelUploader () {
      return ['government', 'retail', 'lead'].includes(this.user?.user_scope)
    },

    ModalTitle () {
      return this.state.editing ? 'Edit Raw Data' : 'Add Raw Data'
    },

    vMOBILENUMBER () {
      return `unique:${this.rawData.id}|min:11|max:11`
    }
  },

  watch: {
    'selected.source' (value) {
      this.rawData.source = value?.id || null
    },

    'selected.cluster' (value) {
      this.rawData.cluster = value?.id || null
      this.getAreas(this.rawData.cluster)
    },

    'selected.area' (value) {
      this.rawData.area = value?.id || null
    },

    'selected.province' (value) {
      this.rawData.province = value?.id || null
      this.getMunicipalities(this.rawData.province)
    },

    'selected.municipality' (value) {
      this.rawData.municipality = value?.id || null
      this.getBarangays(this.rawData.municipality)
    },

    'selected.barangay' (value) {
      this.rawData.barangay = value?.id || null
    },

    'selected2.source' (value) {
      this.excelData.source = value?.id || null
    },

    'selected2.cluster' (value) {
      this.excelData.cluster = value?.id || null
      this.getAreas(this.excelData.cluster)
    },

    'selected2.area' (value) {
      this.excelData.area = value?.id || null
    },

    'selected2.province' (value) {
      this.excelData.province = value?.id || null
      this.getMunicipalities(this.excelData.province)
    },

    'selected2.municipality' (value) {
      this.excelData.municipality = value?.id || null
      this.getBarangays(this.excelData.municipality)
    },

    'selected2.barangay' (value) {
      this.excelData.barangay = value?.id || null
    },

    'excelData.file' (value) {
      if (value) {
        this.onParseExcelFile(value)
      }
    },

    'list.invalidRawData' (value) {
      this.tableInvalidRawData.totalRows = value.length
    },

    'tableCrop.selected.crop' (crop) {
      this.tableCrop.payload.crop_id = crop?.id || null
      this.tableCrop.payload.crop_name = crop?.crop_name || null
    },

    'tableCrop.selected.crop_field_size' (cropFieldSize) {
      this.tableCrop.payload.crop_field_size_id = cropFieldSize?.id || null
      this.tableCrop.payload.crop_field_size_name = cropFieldSize?.field_size_name || null
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getProvinces()
    this.getClusters()
  },

  methods: {

    async tableRawDataProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      return UserRawDataService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_status: filter.status,
          without_mobile_number: filter.without_mobile_number
        })
      ).then(({ data }) => {
        this.tableRawData.totalRows = data.total_rows
        return data.items
      }).catch(() => [])
    },

    async getSources () {
      this.state.fetching.sources = true
      await SharedListService.getSources().then(({ data }) => {
        const filter = [4, 5, 6]
        this.list.sources = data.filter(item => {
          return filter.includes(item.id)
        })
        this.state.fetching.sources = false
      })
    },

    async getClusters () {
      this.state.fetching.clusters = true
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
        this.state.fetching.clusters = false
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      if (cluster) {
        this.state.fetching.areas = true
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data
          this.state.fetching.areas = false
          if (this.state.editing) {
            if (this.selected.current.area) {
              this.selected.area = {
                id: this.selected.current.area.id,
                area_name: this.selected.current.area.area_name
              }
              this.selected.current.area = null
            }
          }
        })
      } else {
        this.list.areas = []
      }
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      this.selected2.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
            if (this.selected2.current.municipality) {
              this.selected2.municipality = {
                id: this.selected2.current.municipality.id,
                municipality_name: this.selected2.current.municipality.municipality_name
              }
              this.selected2.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      this.selected2.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
            if (this.selected2.current.barangay) {
              this.selected2.barangay = {
                id: this.selected2.current.barangay.id,
                barangay_name: this.selected2.current.barangay.barangay_name
              }
              this.selected2.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getCrops () {
      this.tableCrop.options.crops = []
      this.tableCrop.fetching.crops = false
      return new Promise(resolve => {
        SharedListService.getCrops().then(({ data }) => {
          this.tableCrop.options.crops = data
        }).finally(() => {
          resolve([])
          this.tableCrop.fetching.crops = false
        })
      })
    },

    async getFieldSizes () {
      this.tableCrop.options.field_sizes = []
      this.tableCrop.fetching.field_sizes = false
      return new Promise(resolve => {
        SharedListService.getFieldSizes().then(({ data }) => {
          this.tableCrop.options.field_sizes = data
        }).finally(() => {
          resolve([])
          this.tableCrop.fetching.field_sizes = false
        })
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.rawData.id = 0
      this.rawData.active = 1
      this.rawData.first_name = ''
      this.rawData.last_name = ''
      this.rawData.mobile_number = ''
      this.rawData.source = null
      this.rawData.cluster = null
      this.rawData.area = null
      this.rawData.street_name = ''
      this.rawData.province = null
      this.rawData.municipality = null
      this.rawData.barangay = null
      this.rawData.home_address = ''
      this.rawData.farm_address = ''
      this.rawData.remarks = ''

      this.selected.source = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.list.invalidRawData = []

      this.rawData.crops = []
      this.rawData.is_owner = 0

      this.tableCrop.selected.crop = null
      this.tableCrop.selected.crop_field_size = null

      this.tableCrop.payload.order = 0
      this.tableCrop.payload.crop_id = null
      this.tableCrop.payload.crop_name = null
      this.tableCrop.payload.other_crop = null
      this.tableCrop.payload.crop_field_size_id = null
      this.tableCrop.payload.crop_field_size_name = null
      this.tableCrop.payload.number_of_hectares = null

      this.getCrops().then(() => {
        this.getFieldSizes()
      })

      this.$bvModal.show('modal-raw-data-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.rawData.id = current.id
      this.rawData.active = current.active
      this.rawData.first_name = current.first_name
      this.rawData.last_name = current.last_name
      this.rawData.mobile_number = current.mobile_number
      this.rawData.street_name = current.street_name
      this.rawData.home_address = current.home_address
      this.rawData.farm_address = current.farm_address
      this.rawData.remarks = current.remarks

      if (current.source) {
        this.selected.source = {
          id: current.source.id,
          source_name: current.source.source_name
        }
      }

      if (current.cluster) {
        this.selected.cluster = {
          id: current.cluster.id,
          cluster_name: current.cluster.cluster_name
        }
      }

      if (current.area) {
        this.selected.area = {
          id: current.area.id,
          area_name: current.area.area_name
        }
      }

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      this.tableCrop.selected.crop = null
      this.tableCrop.selected.crop_field_size = null

      this.tableCrop.payload.order = 0
      this.tableCrop.payload.crop_id = null
      this.tableCrop.payload.crop_name = null
      this.tableCrop.payload.other_crop = null
      this.tableCrop.payload.crop_field_size_id = null
      this.tableCrop.payload.crop_field_size_name = null
      this.tableCrop.payload.number_of_hectares = null

      this.rawData.is_owner = current.is_owner || 0
      this.rawData.crops = current.crops || []

      this.rawData.crops = this.rawData.crops.sort(
        (a, b) => Number(a.order) - Number(b.order)
      )

      this.getCrops().then(() => {
        this.getFieldSizes()
      })

      this.$bvModal.show('modal-raw-data-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Submit Raw Data?'

          if (this.state.editing) {
            title = 'Save changes on this Raw Data?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: this.state.editing ? 'Save' : 'Submit',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              }
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserRawDataService.post(this.rawData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-raw-data-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserRawDataService.put(this.rawData).then(({ data }) => {
          this.$bvModal.hide('modal-raw-data-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.raw_data.id })
            row.active = data.raw_data.active
            row.status = data.raw_data.status
            row.first_name = data.raw_data.first_name
            row.last_name = data.raw_data.last_name
            row.mobile_number = data.raw_data.mobile_number
            row.street_name = data.raw_data.street_name
            row.home_address = data.raw_data.home_address
            row.farm_address = data.raw_data.farm_address
            row.source = data.raw_data.source
            row.cluster = data.raw_data.cluster
            row.area = data.raw_data.area
            row.province = data.raw_data.province
            row.municipality = data.raw_data.municipality
            row.barangay = data.raw_data.barangay
            row.remarks = data.raw_data.remarks
            row.crops = data.raw_data.crops
            row.is_owner = data.raw_data.is_owner
            row.updated_at = data.raw_data.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onShowImportExcelForm () {
      this.selected2.source = null
      this.selected2.cluster = null
      this.selected2.area = null
      this.selected2.province = null
      this.selected2.municipality = null
      this.selected2.barangay = null
      this.excelData.file = null
      this.list.invalidRawData = []
      this.$bvModal.show('modal-import-from-excel')
    },

    async onParseExcelFile (file) {
      await readXlsxFile(file).then(excelRow => {
        this.rawData.data = []

        if (excelRow.length <= 1) {
          return 'No entries found'
        }

        if (excelRow[0][0] !== 'mobile_number' || excelRow[0][1] !== 'first_name' || excelRow[0][2] !== 'last_name' || excelRow[0][3] !== 'remarks') {
          return 'Column Titles must be (mobile_number, first_name, last_name, remarks)'
        }

        if (excelRow.length >= 501) {
          return 'Entries must not exceed beyond 500'
        }

        // reset list
        this.list.invalidRawData = []
        this.excelData.list = []

        for (let i = 1; i <= excelRow.length - 1; i++) {
          let isOk = true
          let error = ''

          if (excelRow[i][0] === null || excelRow[i][1] === null || excelRow[i][2] === null || excelRow[i][3] === null) {
            isOk = false
            error = 'row value cannot be empty.'
          }

          if (isOk) {
            if (String(excelRow[i][0]).length !== 11) {
              isOk = false
              error = 'mobile_number should be 11 characters.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 50) {
              isOk = false
              error = 'first_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 50) {
              isOk = false
              error = 'last_name should contain maximum of 50 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][3]).length > 3000) {
              isOk = false
              error = 'remarks should contain maximum of 3000 characters only.'
            }
          }

          if (isOk) {
            this.excelData.list.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              remarks: excelRow[i][3],
              line_number: i + 1,
              line_error: ''
            })
          } else {
            this.list.invalidRawData.push({
              mobile_number: excelRow[i][0],
              first_name: excelRow[i][1],
              last_name: excelRow[i][2],
              line_number: i + 1,
              line_error: error
            })
          }
        }

        if (this.list.invalidRawData.length > 0) {
          this.$bvModal.show('modal-invalid-excel-data')
        }

        return null
      }).then(error => {
        if (error) {
          this.$refs.form2.setErrors({
            file: [error]
          })
        }
      }).catch(() => {
        this.$refs.form2.setErrors({
          file: ['There was a problem reading your uploaded excel file.']
        })
      })
    },

    async onConfirmUploadData (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form2.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to upload raw data?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onUploadFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onUploadFormPostSubmit () {
      return new Promise(resolve => {
        this.state.uploading = true
        UserImportDataService.post(this.excelData).then(({ data }) => {
          this.state.uploading = false
          this.$bvModal.hide('modal-import-from-excel')
          this.list.invalidRawData = data.invalid_raw_data

          if (data.total_uploaded === data.total_valid) {
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }

          if (data.total_uploaded === data.total_invalid) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! All the raw data you uploaded in invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }

          if (parseInt(data.total_valid) > 0 && parseInt(data.total_invalid) > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! Some of the raw data you uploaded is invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }
        }).catch(error => {
          this.state.uploading = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onSaveFarmerCrop () {
      this.$refs.formFarmerCrops.validate().then(
        isAllFieldAreValid => {
          if (isAllFieldAreValid) {
            const currentLength = this.rawData.crops.length

            this.tableCrop.payload.order = currentLength + 1

            if (currentLength > 0) {
              this.tableCrop.payload.order = this.rawData.crops[currentLength]?.order ? (
                Number(this.rawData.crops[currentLength]?.order) + 1
              ) : this.tableCrop.payload.order
            }

            this.rawData.crops.push({
              ...this.tableCrop.payload
            })

            this.tableCrop.selected.crop = null
            this.tableCrop.selected.crop_field_size = null

            this.tableCrop.payload.order = 0
            this.tableCrop.payload.crop_id = null
            this.tableCrop.payload.crop_name = null
            this.tableCrop.payload.other_crop = null
            this.tableCrop.payload.crop_field_size_id = null
            this.tableCrop.payload.crop_field_size_name = null
            this.tableCrop.payload.number_of_hectares = null

            this.rawData.crops = this.rawData.crops.sort(
              (a, b) => Number(a.order) - Number(b.order)
            )

            this.$refs.formFarmerCrops.reset()
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    onRemoveFarmerCrop (order) {
      this.rawData.crops = this.rawData.crops.filter(
        crop => Number(crop.order) !== Number(order)
      ).map(
        (raw, order) => ({
          ...raw,
          order: Number(order) + 1
        })
      ).sort(
        (a, b) => Number(a.order) - Number(b.order)
      )
    }

  }
}
</script>
